import React, { useContext, useEffect, useState, useRef } from 'react';
import './DisplayVideo.scss'
import OneVideo from './OneVideo'
import axios from 'axios'
import { UserContext } from '../Context/UserContext';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import VideosHooks from '../../Logic/Media/Videos/VideosHooks';
import { ToastContainer } from 'react-toastify';
export default function DisplayVideo() {
    const[,,categories,attrs,cateID,navigate,pag,page,op,token,visibleModal,fetchVideos,handlePageChange,AddToBook,copiedProductIds,bookMarkRef,handleCopy,handleShareClick,setCategories,,notFound,notFoundRes,activePage]=VideosHooks();
    const { id } = useParams();
    const { baseURL } = useContext(UserContext);
    const [disVideo, setdisVideo] = useState('');
    const [videos, setVideos] = useState('');
    useEffect(() => {
        axios.get(`${baseURL}/media/video/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
            setdisVideo(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    
        axios.get(`${baseURL}/getmedia/3`)
        .then((response) => {
            setVideos(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    
    }, [id, token]); // Include dependencies if they change dynamically
    
    window.addEventListener('hashchange', function (e) {
        e.preventDefault();
    });
    
    window.onload = function () {
        window.history.replaceState(null, null, ' '); // Clear hash if any
    };
    useEffect(() => {
      window.scrollTo(0, 0); // Ensure scroll starts at the top
    }, []);
    const convertVideo = (video) => {
        const videoID = video.match(/(?:youtu\.be\/|v=)([^&/]+)/)[1];
    
        // Ensure meditationsData[0] exists and is an array
        let playlistIDs = [videoID]; // Start with the main video
    
        if (videos.data) {
            videos.data.media[0]?.media.forEach((item) => {
                let listID = item.url.match(/(?:youtu\.be\/|v=)([^&/]+)/)[1];
                playlistIDs.push(listID);
            });
        }
    
        // Create playlist parameter
        const playlistParam = playlistIDs.join(",");
    
        // Construct the final YouTube embed URL
        const videoSrc = `https://www.youtube.com/embed/${videoID}?autoplay=0&loop=1&playlist=${videoID}&controls=1&modestbranding=1&rel=0&showinfo=0&fs=0&disablekb=1`;
    
    
        return videoSrc;
    };

    const [bookmarkedVideos, setBookmarkedVideos] = useState({});

    const toggleBookmark = (video) => {
        const newStatus = !bookmarkedVideos[video.id];
        setBookmarkedVideos((prevState) => ({
            ...prevState,
            [video.id]: newStatus, 
        }));

        AddToBook(video.id, video.bookmarkshow);
    };
    const [status, setStatus] = useState(null);
    const checkVideo = (video) => {
        console.log(video);
        const id = video.match(/(?:youtu\.be\/|v=)([^&/]+)/)[1];

        const img = new Image();
        const thumbUrl = `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
    
        img.onload = function () {
          // Check if the thumbnail is the default placeholder (120x90)
          if (img.width === 120 && img.height === 90) {
            setStatus(false); // Video probably doesn't exist
          } else {
            setStatus(true); // Video exists
          }
        };
    
        img.onerror = function () {
          setStatus(false);
        };
    
        img.src = thumbUrl;
      };
      useEffect(() => {
        if (disVideo) checkVideo(disVideo.video.url);
      }, [disVideo]);

      
    
    return (
        <div className='display-video-parent'>
            <div className='display-video'>
                <div className='bgMediaGallery2'></div>
                <div className="display-video-wrapper">

                    {disVideo.video && (() => {

                        // const videoID = disVideo.video.url.match(/(?:youtu\.be\/|v=)([^&/]+)/)[1];
                        // const videoSrc = `https://www.youtube.com/embed/${videoID}`;
                        return (
                            <>
                                <p className='display-video-about'>{disVideo.video.name}</p>
                                {
                                    status?
                                    <iframe
                                    className='display-video-frame'
                                    src={convertVideo(disVideo.video.url)}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                ></iframe>
                                    :
                                    <video  controls height={400} className='second_video'>
                                        <source src={disVideo.video.second_url} type="video/mp4" />
                                    </video>
                                }
                    
                            </>
                        
                        );
                    })()}
                </div>

            </div>
            <div className='display-video2'>
                <div className="display-video-wrapper">
                    <p className='display-video-about2'>فيديوهات اخري</p>
                    <div className="videos-videos-cont">
                        {disVideo && disVideo.additionals.map((video, index) => {
                            // const videoId = new URL(video.url).searchParams.get('v');
                            // const videoSrc = `https://www.youtube.com/embed/${videoId}`;
                            return (


                                <OneVideo src={convertVideo(video.url)} time={'32:20'} info={video.name} date={video.created_at} id={video.id} index={index} img={video.bookmarkshow? `/assets/bookmark-yellow.png` : `/assets/archive.png`} bookmarkshow={video.bookmarkshow} secondurl={video.second_url}/>
                                
                            )
                        })}

                    </div>
                </div>
            </div>
            <div className='display-video2-res' style={{display:"none"}} dir='rtl'>
                <div className="display-video-wrapper">
                    <p className='display-video-about2'>فيديوهات اخري</p>
                    <div className="videos-videos-cont-res" >
                    <Swiper

                        spaceBetween={10}
                        slidesPerView={1.3}

                        pagination={{
                            clickable: true,
                            el: '.swiper-pagination',
                        }}
                        modules={[ Autoplay, Pagination]}
                        speed={2000}
                        >
                        {
                           disVideo && disVideo.additionals.map((video, index)=>{
                          
                                return(
                                    <SwiperSlide >
                                      <OneVideo src={convertVideo(video.url)} time={'32:20'} info={video.name} date={video.created_at} id={video.id} index={index} img={video.bookmarkshow? `/assets/bookmark-yellow.png` : `/assets/archive.png`} bookmarkshow={video.bookmarkshow}/>

                                    </SwiperSlide>
                                )
                            })
                        }


                        <div className="swiper-pagination"></div>

                        </Swiper>
                       

                    </div>
                </div>
            </div>
            <ToastContainer />

        </div>

    )
}
